<script setup lang="ts">
import { Field, Button, Popup, Picker, showToast, showFailToast, NumberKeyboard } from 'vant';

import { Haa, OeReportType, OeReportSubType } from "@haa/fwk-h5/lib/index";
import { ref, reactive, watch, computed, nextTick } from "vue";
import router from '@/router';
import { useParams } from '@/store/useParams';
import { MOCK_APPINFO } from '@/utils/aes';

import { getVertifyCodeApi, submitReportApi } from '@/apis/resource';

// sdk监听生命周期
Haa.util().useLifeCycle();

const { oeReport, openWindow } = Haa.util().useOeReport();

const showPicker = ref(false);
const columns = [
    { text: '中级会计职称培训', value: '中级会计职称培训' },
    { text: '初级会计职称培训', value: '初级会计职称培训' },
    { text: '高级会计职称培训', value: '高级会计职称培训' },
    { text: '注册会计师cpa', value: '注册会计师cpa' },
    { text: '税务师职业资格证书', value: '税务师职业资格证书' },
    { text: '中级经济师', value: '中级经济师' },
    { text: '初级经济师', value: '初级经济师' },
    { text: '高级经济师', value: '高级经济师' },
    { text: '证券从业培训', value: '证券从业培训' },
];
const onConfirm = ({ selectedOptions }) => {
    form.category = selectedOptions[0].text;
    showPicker.value = false;
    oeReport(OeReportType.Action, OeReportSubType.ActionCollect, form.category, 'CategorySelect');
}

const showNumber = ref(false);
let inputType = '';
const mobileRef = ref(null);
const verifyRef = ref(null);
const keyboardRef = ref(null);
const mobileFocus = () => {
    inputType = 'mobile';
    //@ts-ignore
    keyboardRef.value.style.height = '6rem';
    //@ts-ignore
    keyboardRef.value.scrollIntoView();
    nextTick(() => {
        showNumber.value = true;
    })
}
const verifyFocus = () => {
    inputType = 'verify';
    //@ts-ignore
    keyboardRef.value.style.height = '6rem';
    //@ts-ignore
    keyboardRef.value.scrollIntoView();
    nextTick(() => {
        showNumber.value = true;
    })
}
const onInput = (value: number) => {
    if (!inputType) return;
    if (inputType === 'mobile') {
        form.mobile += value;
    } else if (inputType === 'verify') {
        form.verify_code += value;
    }
}
const onDelete = () => {
    if (!inputType) return;
    if (inputType === 'mobile') {
        form.mobile = form.mobile.substring(0, form.mobile.length - 1);
    } else if (inputType === 'verify') {
        form.verify_code = form.verify_code.substring(0, form.verify_code.length - 1);
    }
}
const onBlur = () => {
    //@ts-ignore
    keyboardRef.value.style.height = '0';
    showNumber.value = false;
    inputType = '';
}

const form = reactive({
    category: '',
    mobile: '',
    verify_code: '',
    username: '',
})
const valid = computed(() => {
    return !!form.category && !!form.mobile && !!form.verify_code
})

const count_disabled = ref(false);
const report_disabled = ref(false);
const message = ref('发送验证码');
let timer: any = null;
let countdown = 120;
const getVertifyCode = (e: any) => {
    e.stopPropagation();
    let reg = /^1[3-9]\d{9}$/;
    if (!reg.test(form.mobile)) {
        showFailToast('手机号码格式不正确');
        return;
    }
    oeReport(OeReportType.Action, OeReportSubType.ActionCollect, JSON.stringify(form), 'VerifyCodeClick');
    getVertifyCodeApi({ mobile: form.mobile, channel: MOCK_APPINFO.channel }).then(res => {
        // TODO 验证发送成功后，倒计时
        if (res) {
            count_disabled.value = true;
            timer = setInterval(() => {
                message.value = --countdown + 's后重新发送';
                if (countdown < 1) {
                    clearInterval(timer);
                    timer = null;
                    message.value = '发送验证码';
                    countdown = 120;
                    count_disabled.value = false;
                }
            }, 1000);
        }
    }).catch(err => {

    })
}

const onSubmit = () => {
    oeReport(OeReportType.Action, OeReportSubType.ActionCollect, JSON.stringify(form), 'SubmitClick');
    if (!valid.value) return;
    const { params } = useParams();
    let submitParams = {
        ...form,
        ...params,
        channel_verify: MOCK_APPINFO.channel,
        app_key: params.key,
    };
    submitReportApi(submitParams).then(res => {
        if (res) {
            onShowResource();
        }
    }).catch(err => {

    })
}

const showResource = ref(false);
const onShowResource = () => {
    showResource.value = true;
}

const copy2clipboard = () => {
    const copyWords = `2024注册会计普通班
        https://pan.quark.cn/s/ecb79c47b36b
        额外赠送
        2024初级会计押题卷第三次万人模考+最后三套卷 
        https://pan.quark.cn/s/2773693b0c31
    `;
    try {
        navigator.clipboard.writeText(copyWords).then(() => {
            showToast('复制成功');
        });
    } catch (err) {
        const textArea = document.createElement('textarea');
        textArea.value = copyWords;
        textArea.style.position = 'fixed';
        textArea.style.top = '0';
        textArea.style.left = '0';
        textArea.style.opacity = '0';
        textArea.setAttribute('readonly', 'readonly');
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand('copy');
        document.body.removeChild(textArea);
        showToast('复制成功');
    }
}
</script>

<template>
    <div class="guide-view-wrapper" v-slide>
        <img class="title" src="@/assets/images/title.png" />
        <div class="sub-title">· 2024最新会计教材 ·</div>
        <div class="divider">
            <span class="divider-title">课程大放送 无套路</span>
            <span class="divider-desc">FREE COURSE</span>
        </div>
        <div class="product">
            <img src="@/assets/images/product.png" />
            <div class="product-intro">
                <div class="p1">超级大礼包</div>
                <div class="p2">+</div>
                <div class="p2">押题卷+三套卷</div>
            </div>
        </div>
        <div class="discounts">
            <div class="origin">原价1999元</div>
            <div class="discounts-t">现免费领取 真实有效</div>
        </div>
        <div class="divider">
            <span class="divider-title">在线登记 直接领取</span>
            <span class="divider-desc">TRUE AND EFFECTIVE</span>
        </div>
        <div class="form">
            <Field
                v-model="form.category"
                is-link
                readonly
                required
                label="课程分类"
                @click="showPicker = true"
            />
            <Field
                v-model="form.username"
                label="姓名"
                placeholder="您的姓名"
            />
            <Field
                v-model="form.mobile"
                label="手机号"
                readonly
                required
                error-message=""
                placeholder="请输入手机号"
                ref="mobileRef"
                @click="mobileFocus"
            />
            <Field
                v-model="form.verify_code"
                label="验证码"
                readonly
                required
                error-message=""
                placeholder="请输入验证码"
                ref="verifyRef"
                @click="verifyFocus"
            >
                <template #button>
                    <Button size="small" :disabled="count_disabled || report_disabled" type="primary" @click="getVertifyCode">{{ message }}</Button>
                </template>
            </Field>
            <div class="submit-btn" :class="valid ? '' : 'disabled'" @click="onSubmit">免费下载 成功上岸</div>
        </div>
        <div ref="keyboardRef"></div>

        <Popup v-model:show="showPicker" round position="bottom">
            <Picker
                :columns="columns"
                @cancel="showPicker=false"
                @confirm="onConfirm"
            />
        </Popup>

        <NumberKeyboard
            :show="showNumber"
            theme="custom"
            extra-key="."
            close-button-text="完成"
            @blur="onBlur"
            @input="onInput"
            @delete="onDelete"
        />

        <Popup class="resource-pop" v-model:show="showResource" round position="bottom">
            <div class="resource-box">
                <div>超级大礼包</div>
                <div>2024注册会计普通班</div>
                <a class="link" href="https://pan.quark.cn/s/ecb79c47b36b" target="_blank">https://pan.quark.cn/s/ecb79c47b36b</a>
            </div>
            <div class="resource-box">
                <div>额外赠送</div>
                <div>2024初级会计押题卷第三次万人模考+最后三套卷</div>
                <a class="link" href="https://pan.quark.cn/s/2773693b0c31" target="_blank">https://pan.quark.cn/s/2773693b0c31</a>
            </div>
            <Button class="copy-btn" plain round block type="primary" @click="copy2clipboard">一键复制</Button>
        </Popup>
    </div>
</template>

<style scoped lang="less">
.guide-view-wrapper {
    height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
    background-image: url('@/assets/images/bg.png');
    background-size: 100% 100%;
    padding: 30px;
    box-sizing: border-box;
    .title {
        width: 400px;
    }
    .sub-title {
        background: linear-gradient(90deg, #58EBFF 0%, #5484FF 100%);
        width: 400px;
        height: 77px;
        line-height: 77px;
        border-radius: 77px;
        text-align: center;
        color: #fff;
        font-size: 33px;
        margin-bottom: 60px;
    }
    .divider {
        display: flex;
        justify-content: space-between;
        margin-bottom: 30px;
        span {
            flex: 1;
        }
        .divider-title {
            font-size: 40px;
            color: #15254A;
            font-weight: bold;
        }
        .divider-desc {
            display: flex;
            font-size: 30px;
            color: #68B5F4;
            border-bottom: 1px solid #A8D8FF;
            justify-content: end;
            align-items: end;
        }
    }
    .product {
        position: relative;
        img {
            width: 100%;
            transform: scale(1.05);
        }
        .product-intro {
            width: 273px;
            position: absolute;
            right: 0;top: 30px;
            font-size: 40px;
            font-weight: bold;
            text-align: center;
            .p1 {
                color: #080B60;
            }
            .p2 {
                color: #1017C6;
            }
        }
    }
    .discounts {
        display: flex;
        justify-content: space-between;
        margin-bottom: 30px;
        .origin {
            width: 250px;
            height: 72px;
            line-height: 72px;
            background: linear-gradient(90deg, #4B5167 0%, #7F818C 100%);
            border-radius: 72px 10px 10px 72px;
            text-align: center;
            color: #EFEFEF;
            font-size: 32px;
            margin-right: 10px;
            text-decoration: line-through;
        }
        .discounts-t {
            flex: 1;
            height: 72px;
            line-height: 72px;
            background: linear-gradient(90deg, #385AD3 0%, #1F2EAF 100%);
            border-radius: 10px 72px 72px 10px;
            text-align: center;
            color: #EFEFEF;
            font-size: 35px;
            font-weight: bold;
        }
    }
    .van-cell {
        border-radius: 80px;
        margin-bottom: 20px;
    }
    .form {
        .submit-btn {
            width: 528px;
            height: 90px;
            border-radius: 90px;
            line-height: 90px;
            text-align: center;
            margin: 0 auto 30px;
            font-size: 36px;
            color: #EFEFEF;
            background: linear-gradient(90deg, #4047FF 0%, #FF4343 100%);
            &.disabled {
                background: linear-gradient(90deg, #4E4E59 0%, #353131 100%);
            }
        }
    }
    .resource-pop {
        padding: 0 30px;
        box-sizing: border-box;
        background: linear-gradient(163.63deg, #C4E7FF 2.38%, #FFFEEA 33.06%, #FFFFFF 88.65%);
    }
    .resource-box {
        font-size: 35px;
        margin: 60px 0;
        .link {
            color: #1581FF;
            margin-top: 10px;
        }
    }
    .copy-btn {
        width: 550px;
        margin: 60px auto;
    }
}
</style>
