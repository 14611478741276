import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import GuideView from '../views/GuideView.vue';
import { Haa, OeReportType, OeReportSubType } from '@haa/fwk-h5/lib/index';
const { oeReport } = Haa.util().useOeReport();

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'Guide',
        component: GuideView,
    },
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    next();
})

export default router;
