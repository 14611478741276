import { request } from '@/utils/service';
import { getMessageQuery } from '@/utils/aes';

/** 获取短信验证码 */
export function getVertifyCodeApi(data: any) {
    return request({
        url: `/api/login/sendVerifyNewH5?${getMessageQuery()}`,
        method: 'post',
        data,
    })
}

export function submitReportApi(data: any) {
    return request({
        url: '/api/login/report',
        method: 'post',
        data,
    })
}