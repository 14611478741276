import CryptoJS from 'crypto-js';

const key = CryptoJS.enc.Utf8.parse('47FC3111CF23E079');
const iv = CryptoJS.enc.Utf8.parse('ovOa2xYoRBdATob1');
// aes加密方法
export const AesEncode = function (word: string) {
    let srcs = CryptoJS.enc.Utf8.parse(word);
    let encrypted = CryptoJS.AES.encrypt(srcs, key, { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });
    let encryptedBase64Data = CryptoJS.enc.Base64.stringify(encrypted.ciphertext);
    return encodeURIComponent(encryptedBase64Data);
}

// aes解密方法
export const AesDecode = function (word: string) {
    try {
        word = decodeURIComponent(word)
        let encryptedHexStr = CryptoJS.enc.Base64.parse(word);
        let srcs = CryptoJS.enc.Base64.stringify(encryptedHexStr);
        let decrypt = CryptoJS.AES.decrypt(srcs, key, { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });
        // console.error(decrypt);
        let decryptedStr = decrypt.toString(CryptoJS.enc.Utf8);
        return decryptedStr.toString();
    } catch (e) {
        console.error(e);
    }
}

export const MOCK_APPINFO = {
    package: 'com.enjoy.view.fapp',
    channel: 'yuelan',
};

export const getMessageQuery = function () {
    let params = { key: MOCK_APPINFO.package, timestamp: "" + new Date().getTime() };
    return `token=${AesEncode(JSON.stringify(params))}`;
}